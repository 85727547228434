import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "home"
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home.vue")
  },
  {
    path: "/explore",
    name: "explore",
    component: () => import("@/views/Explore.vue")
  },
  {
    path: "/me",
    name: "me",
    component: () => import("@/views/Me.vue")
  },
  {
    path: "/home/productPre",
    name: "productPre",
    component: () => import("@/views/home/ProductPre.vue")
  },
  {
    path: "/home/surround",
    name: "surround",
    component: () => import("@/views/home/Surround.vue")
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

// 路由守卫
router.beforeEach((to, from, next) => {
  next();
});


export default router;
