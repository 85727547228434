<template>
  <div>
    <router-view></router-view>
  </div>
</template>


<script setup>

</script>


<style scoped lang="scss">

</style>
