import { createApp } from "vue";
import App from "@/App.vue";
const app = createApp(App);

import router from "@/router/index";
app.use(router);

import { installVant } from "@/common/importVant";
installVant(app);

import ElementPLus from 'element-plus'
import 'element-plus/dist/index.css'
app.use(ElementPLus)


// import BaiduMap from "vue-baidu-map-3x";
// app.use(BaiduMap, {
//    ak: 'Nb7tSbKo1xNGqjXz8AF9aX5q',
// });


// app.config.globalProperties.$TMap = window.TMap


app.mount("#app");

